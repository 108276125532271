// Module imports.
import { useState, useEffect, useCallback } from 'react';
import api from '../util/api';
import useAsyncError from './useAsyncError.js';

const useApi = ({ method, url, params = {}, responseType = 'json' }, { callIfTruthy = true } = {}) => {
	// Set the state and other parameters.
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [response, setResponse] = useState(null);
	const throwError = useAsyncError();
	const paramsString = JSON.stringify(params);

	// Call api function using react callback hook.
	const callApi = useCallback(async () => {
		try {
			const response = await api({ method, url, params, responseType })
			setResponse(response);
			setData(response.data);

			setIsLoading(false);
		} catch (err) {
			setError(err);
			throwError(err);
		}

	}, [method, url, params, throwError, responseType])

	// Call api once on mount.
	useEffect(() => {
		if (callIfTruthy) {
			setIsLoading(true);
			callApi();
		}
		// Why is url not working above.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url, paramsString, callIfTruthy, setIsLoading])

	return { response, data, callApi, isLoading, error }
}

export default useApi;
